import React, { useRef, useState, useEffect } from "react";
import {
  Input,
  Image,
  Menu,
  Checkbox,
  Message,
  Popover,
} from "@arco-design/web-react";
import { delete_caption, smart_b_icon } from "@/assets/creationscriptimage";
import SmartSearch from "@/components/business/SmartSearch";
import VideoUploader from "@/components/business/VideoUploader";
import MyEditor from "@/components/business/MyEditor";
import "./index.less";
import { toJS } from "mobx";
import debounce from "lodash.debounce";
import _ from "lodash";
import User from "@/stores/userStore";
import creationStores from "@/stores/creationScriptStore";
import { user } from "@/assets/user-center";

const TextArea = Input.TextArea;
// 选中的文本以及位置
const handleUpdate = debounce((index, type, val) => {
  creationStores.changeCaption(index, type, val);
}, 100);
function useData({ dataSendFUn, sendCursor }) {
  // 表格创建的默认数据
  const delay = (callback, time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        callback();
        resolve(1);
      }, time);
    });
  };
  const defaultText = [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "",
        },
      ],
    },
  ];
  const defaultInitialData = () => {
    const item = {
      storyboardName: defaultText,
      pictureDescription: defaultText,
      caption: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
      ],
      materials: [],
    };
    const firstObject = creationStores.dataSource[0];
    if (firstObject) {
      const columns = Object.keys(firstObject).filter((v) => v != "key");
      const captions = Object.keys(firstObject).filter((key) =>
        key.startsWith("caption_"),
      );
      captions.forEach((v) => {
        item[v] = [
          {
            type: "listItem",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
        ];
      });
      columns.forEach((v) => {
        if (v.startsWith("caption")) {
          item[v] = [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
          ];
        } else if (v == "materials") {
          item[v] = [];
        } else {
          item[v] = defaultText;
        }
      });
    }
    return item;
  };

  // AI小助手
  const [suspendAi, setSuspendAi] = useState(false);
  // 智能文案状态
  const [selectStatus, setSelectStatus] = useState<any>("");
  // 输入框传递智能文案内容
  const [helpWrite, setHelpWrite] = useState("");
  // 智能文案输入框显示隐藏的下标
  const [showSmartInputIndex, setShowSmartInputIndex] = useState(-1);
  const [showSmartInputI, setShowSmartInputI] = useState(-1);
  // 智能文案图标显示隐藏下标
  const [showSmartIndex, setShowSmartIndex] = useState(-1);
  const showSmartIndexRef = useRef(-1);
  const [captionList, setCaptionList] = useState([]);

  useEffect(() => {
    showSmartIndexRef.current = showSmartIndex;
  }, [showSmartIndex]);
  const showSmartInputIndexRef = useRef(-1);
  useEffect(() => {
    showSmartInputIndexRef.current = showSmartInputIndex;
  }, [showSmartInputIndex]);

  const [showSmartI, setShowSmartI] = useState(-1);
  const showSmartIRef = useRef(-1);
  useEffect(() => {
    showSmartIRef.current = showSmartI;
  }, [showSmartI]);
  // 光标定位
  const [location, setlocation] = useState(true);
  const [intelligentValue, setIntelligentValue] = useState<any>();
  const [intelligentUnderway, setIntelligentUnderway] = useState(false);
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    i: -1,
    range: {
      from: -1,
      to: -1,
    },
  });
  const selectInfoRef = useRef(null);
  // 光标信息
  useEffect(() => {
    selectInfoRef.current = selectInfo;
  }, [selectInfo]);
  // 补零
  const setNum = (num) => {
    return num >= 10 ? num : `0${num}`;
  };
  const editorHandler = useRef<any>([]);

  const findText = (objArray) => {
    let result = [];
    objArray.content.forEach((obj) => {
      if (obj.type.name === "text") {
        result.push(obj.text);
      } else if (obj.content) {
        result = result.concat(findText(obj));
      }
    });
    return result.join("");
  };

  // 修改数组key值
  const insertAndUpdateKeys = (
    arr,
    action,
    newElementNameOrKeyToDelete,
    insertAfterKey = null,
  ) => {
    // 复制原数组，避免直接修改原数组
    const newArr = [...arr];
    const firstObject = arr[0];

    if (action === "add") {
      // 找到插入位置
      const insertIndex = newArr.findIndex(
        (element) => element.key === insertAfterKey,
      );
      if (firstObject) {
        const captionKeys = Object.keys(firstObject).filter(
          (key) => key.startsWith("caption_") && key != "caption_01",
        );
        captionKeys.forEach((item) => {
          newElementNameOrKeyToDelete[item] = [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
          ];
        });
      }
      // 生成新元素的key值
      const newElementKey = newArr.length
        ? newArr.reduce((max, item) => {
            return Math.max(max, item.key);
          }, -Infinity) + 1
        : 1;
      // 插入新元素
      newArr.splice(insertAfterKey == -1 ? newArr.length : insertIndex + 1, 0, {
        key: newElementKey,
        ...newElementNameOrKeyToDelete,
        checked: false,
      });
    } else if (action === "delete") {
      // 找到要删除元素的索引
      const deleteIndex = newArr.findIndex(
        (element) => element.key === newElementNameOrKeyToDelete,
      );
      if (deleteIndex !== -1) {
        // 删除元素
        newArr.splice(deleteIndex, 1);
      }
    }

    return newArr;
  };

  const setCursorFUn = (index, i, pos) => {
    sendCursor({
      RowId: index,
      ColId: i,
      position: pos.from,
      uid: User?.userInfo?.uuid,
      range: pos,
    });
  };

  // 新改版smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(false);
  const [smartPostion, setSmartPostion] = useState<any>({});

  const tdRef = useRef([]);
  const useRenderEdit = (
    col,
    index,
    maxLength,
    placeholder,
    type,
    i,
    cursorInfo,
    maxHeight,
    setHandleClickIndx,
  ) => {
    return (
      <MyEditor
        ref={(ref) => {
          if (!editorHandler.current[index]) {
            editorHandler.current[index] = []; // 初始化该行
          }

          editorHandler.current[index][i] = ref;
          return false;
        }}
        isShowTimer
        editorCurrentStyle={{ maxHeight }}
        cursor={cursorInfo.find((v: any) => v.RowId == index && v.ColId == i)}
        content={
          col
            ? toJS(col)
            : [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "",
                    },
                  ],
                },
              ]
        }
        placeholder={placeholder}
        sendInfo={dataSendFUn}
        type={type}
        limit={maxLength}
        handlefocus={() => {
          setHandleClickIndx([index, i]);
          if (type.includes("caption")) {
            setShowSmartIndex(index);
            setShowSmartInputIndex(-1);
            setShowSmartInputI(-1);
            if (selectStatus) {
              setlocation(false);
            }
          }
          setShowSmartI(i);
        }}
        handleblur={() => {
          setHandleClickIndx([-1, -1]);
          // sendCursor({
          //   RowId: -1,
          //   ColId: -1,
          //   position: 0,
          //   uid: User?.userInfo?.uuid,
          //   range: {
          //     from: -1,
          //     to: -1,
          //   },
          // });
        }}
        selected={({ value, range }) => {
          setSmartValue(
            value ||
              editorHandler.current[index][i].editor
                .getText()
                .replace(/\n\n\n\n/g, "\n")
                .slice(1),
          );
          setSelectInfo({
            index,
            i,
            range,
          });
        }}
        setEditPosition={(ops) => {
          setCursorFUn(index, i, ops);
        }}
        selectInfo={selectInfo}
        handleUpdate={(val) => handleUpdate(index, type, val)}
        limitPosition={{ bottom: 6, right: 10 }}
      />
    );
  };
  const captionListRef = useRef([]);

  const columns: any = [
    {
      title: "分镜名称",
      width: 244,
      messageInfo: "",
      dataIndex: "storyboardName",
      maxLength: 20,
      render: (
        col,
        index,
        maxLength,
        i,
        cursorInfo,
        setHandleClickIndx,
        dataIndex,
      ) => {
        return (
          <div className="td-box">
            <p className="storyboardName-text">{`#${setNum(index + 1)}`}</p>
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入镜头名称，例如：店家门头",
              dataIndex,
              i,
              cursorInfo,
              140,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "画面描述",
      width: 244,
      messageInfo: "",
      maxLength: 200,
      dataIndex: "pictureDescription",
      render: (
        col,
        index,
        maxLength,
        i,
        cursorInfo,
        setHandleClickIndx,
        dataIndex,
      ) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "对于当前镜头画面的描述",
              dataIndex,
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "台词文案",
      width: 244,
      maxLength: 1000,
      // messageInfo: "shift+enter行内折行",
      dataIndex: "caption",
      render: (
        col,
        index,
        maxLength,
        i,
        cursorInfo,
        setHandleClickIndx,
        dataIndex,
      ) => {
        return (
          <div
            className="td-box"
            ref={(ref) => {
              if (!tdRef.current[index]) {
                tdRef.current[index] = [];
              }
              tdRef.current[index][i] = ref;
            }}>
            {useRenderEdit(
              col,
              index,
              maxLength,
              "shift + enter 行内折行",
              dataIndex,
              i,
              cursorInfo,
              165,
              setHandleClickIndx,
            )}
            <Image
              onMouseUp={() => {
                setShowSmartInputIndex(index);
                setShowSmartInputI(i);
                delay(() => {
                  setShowSmartIndex(-1);
                }, 100);
              }}
              className="smart-b-icon"
              style={{
                display:
                  showSmartIndex === index && i == showSmartI
                    ? "block"
                    : "none",
              }}
              width={111}
              preview={false}
              src={smart_b_icon}
            />
            <div
              style={{
                display:
                  showSmartInputIndex === index && i == showSmartI
                    ? "block"
                    : "none",
              }}>
              <SmartSearch
                styles={{
                  left: smartPostion.left,
                  top: smartPostion.top + 188,
                  zIndex: 99999,
                }}
                placeholder="帮我写..."
                handelClickBtn={(value: string, type: string) => {
                  setSmartStatus(type);
                  setSendSmartStatus(value);
                  setShowSmartInputIndex(-1);
                  setShowSmartInputI(-1);
                  setSuspendAi(false);
                }}
                editValue={smartValue}
                isEnd={itelligentIsExecution}
                handleKeyDown={(event) => {
                  if (event.key === "Escape" || event.keyCode === 27) {
                    setShowSmartInputIndex(-1);
                    setShowSmartInputI(-1);
                  }
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "素材",
      width: 244,
      messageInfo: "",
      maxLength: 200,
      dataIndex: "materials",
      render: (col, index) => {
        return (
          <div
            className="td-box"
            style={{
              height: 212,
              maxHeight: 178,
              overflow: "auto",
              paddingBottom: 0,
            }}>
            <VideoUploader index={index} value={col} send={dataSendFUn} />
          </div>
        );
      },
    },
  ];
  const handleDeleteCaptions = (dataIndex) => {
    const newData = [...creationStores.dataSource];
    newData.forEach((item) => {
      delete item[dataIndex];
    });
    creationStores.compareUpdate(newData);
  };
  // 要添加的列
  const [menuData, setMenuData] = useState([
    {
      title: "景别",
      width: 244,
      dataIndex: "view",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入景别",
              "view",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "模特",
      width: 244,
      messageInfo: "",
      dataIndex: "model",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入模特",
              "model",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "运镜",
      width: 244,
      messageInfo: "",
      dataIndex: "cameraMovement",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入运镜",
              "cameraMovement",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "场地",
      width: 244,
      dataIndex: "place",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入场地",
              "place",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "道具",
      width: 244,
      messageInfo: "",
      dataIndex: "prop",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入道具",
              "prop",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
    {
      title: "备注",
      width: 244,
      messageInfo: "",
      dataIndex: "remark",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo, setHandleClickIndx) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入备注",
              "remark",
              i,
              cursorInfo,
              170,
              setHandleClickIndx,
            )}
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    const target =
      tdRef.current[showSmartInputIndex] &&
      tdRef.current[showSmartInputIndex][showSmartInputI];
    if (!target) return;
    setSmartPostion(target.getBoundingClientRect());
  }, [showSmartInputIndex]);

  // 解决添加分组
  const dropList = () => {
    return (
      <Menu onClickMenuItem={() => false}>
        {menuData.map((v, i) => (
          <Menu.Item key={`${i}`} style={{ width: 136 }}>
            <Checkbox
              style={{ marginRight: 9 }}
              checked={v.checked}
              onChange={() => {
                menuData[i].checked = !menuData[i].checked;
                menuData.filter((v) => v.checked);
                const newData = [...creationStores.dataSource];
                newData.forEach((v) => {
                  if (menuData[i].checked) {
                    v[menuData[i].dataIndex] = defaultText;
                  } else {
                    delete v[menuData[i].dataIndex];
                  }
                });
                creationStores.compareUpdate(newData);
                setMenuData([...menuData]);
              }}
            />
            {v.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const addCaptions = () => {
    const newCaption = [...columns];
    const firstObject = creationStores.dataSource[0];
    const arr = [];
    if (firstObject) {
      const captions = Object.keys(firstObject).filter((key) =>
        key.startsWith("caption_"),
      );
      Object.keys(firstObject).forEach((key) => {
        const index = menuData.findIndex((v) => v.dataIndex == key);
        if (index != -1) {
          menuData[index].checked = true;
        }
      });
      // setMenuData([...menuData]);
      const captionIndex = newCaption.findIndex(
        (v) => v.dataIndex == "caption",
      );
      if (captions.length >= 1) {
        newCaption[captionIndex].title = "台词文案01";
      }
      captions.forEach((item, index) => {
        arr.push({
          title: `台词文案${setNum(index + 2)}`,
          width: 244,
          maxLength: 1000,
          // messageInfo: "",
          thTool: (
            <Popover
              content={
                <div
                  style={{
                    color: "white",
                    padding: "4px 8px",
                  }}>
                  删除台词组
                </div>
              }
              trigger={"hover"}
              color={"black"}>
              <img
                className="delete-caption"
                width={14}
                src={delete_caption}
                alt=""
                onClick={() => {
                  handleDeleteCaptions(item);
                }}
              />
            </Popover>
          ),
          dataIndex: item,
          render: (
            col,
            index,
            maxLength,
            i,
            cursorInfo,
            setHandleClickIndx,
            dataIndex,
          ) => {
            return (
              <div
                className="td-box"
                ref={(ref) => {
                  if (!tdRef.current[index]) {
                    tdRef.current[index] = [];
                  }
                  tdRef.current[index][i] = ref;
                }}>
                {useRenderEdit(
                  col,
                  index,
                  maxLength,
                  "Shift + enter 行内折行",
                  dataIndex,
                  i,
                  cursorInfo,
                  165,
                  setHandleClickIndx,
                )}
                <Image
                  onMouseUp={() => {
                    setShowSmartInputIndex(index);
                    setShowSmartInputI(i);
                    delay(() => {
                      setShowSmartIndex(-1);
                    }, 100);
                  }}
                  className="smart-b-icon"
                  style={{
                    display:
                      showSmartIndexRef.current === index &&
                      i == showSmartIRef.current
                        ? "block"
                        : "none",
                  }}
                  width={111}
                  preview={false}
                  src={smart_b_icon}
                />
                <div
                  style={{
                    display:
                      showSmartInputIndexRef.current === index &&
                      i == showSmartIRef.current
                        ? "block"
                        : "none",
                  }}>
                  <SmartSearch
                    styles={{
                      left: smartPostion.left,
                      top: smartPostion.top + 188,
                      zIndex: 99999,
                    }}
                    placeholder="帮我写..."
                    handelClickBtn={(value: string, type: string) => {
                      setSmartStatus(type);
                      setSendSmartStatus(value);
                      setShowSmartInputIndex(-1);
                      setShowSmartInputI(-1);
                      setSuspendAi(false);
                    }}
                    editValue={smartValue}
                    isEnd={itelligentIsExecution}
                    handleKeyDown={(event) => {
                      if (event.key === "Escape" || event.keyCode === 27) {
                        setShowSmartInputIndex(-1);
                        setShowSmartInputI(-1);
                      }
                    }}
                  />
                </div>
              </div>
            );
          },
        });
      });

      newCaption.splice(captionIndex + 1, 0, ...arr);
    }
    return [...newCaption, ...menuData.filter((v) => v.checked)];
  };

  const handleAddCaptions = () => {
    // 判断是否存在以 caption_ 开头的键
    const firstObject = creationStores.dataSource[0];
    const newData = [...creationStores.dataSource];

    if (!firstObject) {
      // Message.warning("请先添加分组");
      return;
    }

    const caption_index = Object.keys(firstObject).filter((key) =>
      key.startsWith("caption_"),
    );

    // 如果存在以 caption_ 开头的键
    if (caption_index.length > 0) {
      // 获取最大的 caption_XX 的编号

      const maxCaptionIndex = Math.max(
        ...caption_index.map((key) => parseInt(key.split("caption_")[1], 10)),
      );

      // 创建新的 caption_XX 键（例如 caption_03）
      const newCaptionKey = `caption_${setNum(maxCaptionIndex + 1)}`;
      // 添加新的 caption_XX
      newData.forEach((item) => {
        item[newCaptionKey] = [
          {
            type: "listItem",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
        ];
      });
      // 添加列
    } else {
      // 如果没有以 caption_ 开头的键，则将 caption 修改为 caption_01
      newData.forEach((item) => {
        item["caption_02"] = [
          {
            type: "listItem",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
        ];
      });
    }

    creationStores.compareUpdate(newData);
  };
  const handleSetCaptions = () => {
    // 判断是否存在以 caption_ 开头的键
    const firstObject = creationStores.dataSource[0];
    if (!firstObject) {
      // Message.warning("请先添加分组");
      return;
    }
    const captionKeys = Object.keys(firstObject).filter(
      (key) => key.startsWith("caption_") && key != "caption_01",
    );

    // 如果存在以 caption_ 开头的键
    if (captionKeys.length > 0) {
      const newCaption = [...captionListRef.current];
      captionKeys.forEach((item, index) => {
        newCaption.push({
          title: `台词文案${setNum(index + 2)}`,
          width: 244,
          maxLength: 1000,
          // messageInfo: "",
          thTool: (
            <img
              className="delete-caption"
              width={14}
              src={delete_caption}
              alt=""
              onClick={() => {
                handleDeleteCaptions(item);
              }}
            />
          ),
          dataIndex: item,
          render: (
            col,
            index,
            maxLength,
            i,
            cursorInfo,
            setHandleClickIndx,
            dataIndex,
          ) => {
            return (
              <div className="td-box">
                {useRenderEdit(
                  col,
                  index,
                  maxLength,
                  "Shift + enter 行内折行",
                  dataIndex,
                  i,
                  cursorInfo,
                  165,
                  setHandleClickIndx,
                )}
                <Image
                  onMouseUp={() => {
                    setShowSmartInputIndex(index);
                    setShowSmartInputI(i);
                    delay(() => {
                      setShowSmartIndex(-1);
                    }, 100);
                  }}
                  className="smart-b-icon"
                  style={{
                    display:
                      showSmartIndexRef.current === index &&
                      i == showSmartIRef.current
                        ? "block"
                        : "none",
                  }}
                  width={111}
                  preview={false}
                  src={smart_b_icon}
                />
                <div
                  style={{
                    display:
                      showSmartInputIndex === index && i == showSmartI
                        ? "block"
                        : "none",
                  }}>
                  <SmartSearch
                    styles={{
                      left: 0,
                    }}
                    placeholder="帮我写..."
                    handelClickBtn={(value: string, type: string) => {
                      setSmartStatus(type);
                      setSendSmartStatus(value);
                      setShowSmartInputIndex(-1);
                      setShowSmartInputI(-1);
                      setSuspendAi(false);
                    }}
                    editValue={smartValue}
                    isEnd={itelligentIsExecution}
                    handleKeyDown={(event) => {
                      if (event.key === "Escape" || event.keyCode === 27) {
                        setShowSmartInputIndex(-1);
                        setShowSmartInputI(-1);
                      }
                    }}
                  />
                </div>
              </div>
            );
          },
        });
      });
      captionListRef.current = _.uniqBy(newCaption, "dataIndex");
    }
  };
  useEffect(() => {
    setCaptionList(_.uniqBy(captionListRef.current, "dataIndex"));
  }, [captionListRef.current]);

  return {
    menuData,
    columns,
    dropList,
    defaultInitialData,
    insertAndUpdateKeys,
    helpWrite,
    selectStatus,
    setSelectStatus,
    suspendAi,
    setSuspendAi,
    location,
    selectInfo,
    editorHandler,
    intelligentValue,
    setIntelligentUnderway,
    smartStatus,
    sendSmartStatus,
    setItelligentIsExecution,
    setSendSmartStatus,
    handleAddCaptions,
    captionList,
    handleSetCaptions,
    captionListRef,
    addCaptions,
  };
}

export default useData;
